export default class Filters {
	constructor(parent) {
		this.parent = parent;
		this.dataGroup = 'filter-group';
		this.dataId = 'filter-id';
		this.dataQuery = 'filter-query';
		this.filterGroups = [];
		this.filterList = $('#filter-list');

		$('[data-' + this.dataGroup + ']')
			.on('click', e => this.filterClicked(e))
			.each((i, e) => {
				const fn = $(e).data(this.dataGroup);
				if (this.filterGroups.indexOf(fn) === -1) {
					this.filterGroups.push(fn);
				}
			});

		let query = '';
		if (window.location.hash.length > 1) {
			let data = new URLSearchParams(
				decodeURI(window.location.hash.slice(1)),
			);
			query = data.get('query');
			data.forEach((value, group) => {
				if (group !== 'query') {
					this.markActive(
						group.indexOf('[') > 0
							? group.slice(0, group.indexOf('['))
							: group,
						value,
					);
				}
			});
		}

		$('[data-' + this.dataQuery + ']')
			.val(query)
			.on('change', e => this.queryChanged(e));
	}

	dataForQuery() {
		const data = {
			query: $('[data-' + this.dataQuery + ']').val(),
		};
		$('[data-' + this.dataGroup + '].active').each((i, e) => {
			const name = $(e).data(this.dataGroup);
			const id = $(e).data(this.dataId);
			if (!Object.prototype.hasOwnProperty.call(data, name)) {
				data[name] = [];
			}
			if (name === 'year' || name === 'month') {
				data[name] = id;
			} else {
				data[name].push(id);
			}
		});
		return data;
	}

	filterClicked(el) {
		$(el.currentTarget).toggleClass('active');
		$(window).trigger('filter:changed');
	}

	queryChanged(el) {
		$(window).trigger('filter:changed');
	}

	fromServerResponse(obj) {
		$('[data-' + this.dataGroup + ']').removeClass('active');
		for (let group in obj) {
			if (obj[group] === null) {
				// is Null
			} else if (group === 'query') {
				$('[data-' + this.dataQuery + ']').val(obj[group]);
			} else {
				if (typeof obj[group] === 'string') {
					this.markActive(group, obj[group]);
				} else {
					for (let i in obj[group]) {
						this.markActive(group, obj[group][i]);
					}
				}
			}
		}
	}

	markActive(group, id) {
		$(
			'[data-' +
				this.dataGroup +
				'="' +
				group +
				'"][data-' +
				this.dataId +
				'="' +
				id +
				'"]',
		).addClass('active');
	}

	markInactive(group, id) {
		$(
			'[data-' +
				this.dataGroup +
				'="' +
				group +
				'"][data-' +
				this.dataId +
				'="' +
				id +
				'"]',
		).removeClass('active');
	}

	updateFilterList(html) {
		if (this.filterList.length > 0) {
			this.filterList.html(html);
			$('[data-filter-close]')
				.off('click')
				.on('click', e =>
					this.removeFilter($(e.currentTarget).data('filter-close')),
				);
		}
	}

	removeFilter(q) {
		if (q === 'all') {
			$('[data-' + this.dataQuery + ']').val('');
			$('[data-' + this.dataGroup + ']').removeClass('active');
			this.parent.datepicker.datepickerStart.setDate({ clear: true });
			this.parent.datepicker.datepickerEnd.setDate({ clear: true });
		} else {
			let qs = q.split(',');
			if (qs[0] === 'start') {
				this.parent.datepicker.datepickerStart.setDate({ clear: true });
			} else if (qs[0] === 'end') {
				this.parent.datepicker.datepickerEnd.setDate({ clear: true });
			} else {
				this.markInactive(qs[0], qs[1]);
			}
		}
		$(window).trigger('filter:changed');
	}
}
