import { Datepicker } from 'vanillajs-datepicker';
import csLocale from 'vanillajs-datepicker/locales/cs';
import enLocale from 'vanillajs-datepicker/locales/en-GB';
import deLocale from 'vanillajs-datepicker/locales/de';
import plLocale from 'vanillajs-datepicker/locales/pl';
import huLocale from 'vanillajs-datepicker/locales/hu';
import esLocale from 'vanillajs-datepicker/locales/es';
import frLocale from 'vanillajs-datepicker/locales/fr';

Object.assign(
	Datepicker.locales,
	csLocale,
	enLocale,
	deLocale,
	plLocale,
	huLocale,
	esLocale,
	frLocale,
);

export default class calendarDateTimePicker {
	constructor() {
		this.calendarElStart = document.getElementById(
			'calendar-datepicker-start',
		);
		this.calendarElEnd = document.getElementById('calendar-datepicker-end');
		this.datepickerStart = null;
		this.datepickerEnd = null;
		if (this.calendarElStart) {
			this.locale = this.calendarElStart.dataset.locale;
			this.datepickerStart = new Datepicker(this.calendarElStart, {
				language: this.locale,
				minDate: new Date(),
				defaultViewDate: new Date(),
				format: 'yyyy-mm-dd',
				daysOfWeekHighlighted: [6, 0],
				todayBtn: true,
				todayHighlight: true,
			});
			this.datepickerStart.setDate(this.getInitialDate('start'));
		}

		this.initDatepickerEnd();

		if (this.datepickerStart) {
			this.datepickerStart.element.addEventListener(
				'changeDate',
				event => {
					$(window).trigger('filter:changed');
					this.datepickerEnd.destroy();
					this.initDatepickerEnd();
				},
			);
		}

		if (this.datepickerEnd) {
			this.datepickerEnd.element.addEventListener('changeDate', event => {
				$(window).trigger('filter:changed');
			});
		}
		return this;
	}

	getInitialDate(f) {
		let uriData = new URLSearchParams(
			decodeURI(window.location.hash.slice(1)),
		);

		if (uriData.has(f)) {
			return new Date(uriData.get(f));
		}
		return null;
	}

	initDatepickerEnd() {
		if (this.calendarElEnd && this.datepickerStart !== null) {
			this.locale = this.calendarElEnd.dataset.locale;
			this.datepickerEnd = new Datepicker(this.calendarElEnd, {
				language: this.locale,
				minDate: this.datepickerStart.getDate(),
				defaultViewDate: this.datepickerStart.getDate(),
				value: this.getInitialDate('end'),
				format: 'yyyy-mm-dd',
				daysOfWeekHighlighted: [6, 0],
				todayBtn: true,
				todayHighlight: true,
			});
			this.datepickerEnd.setDate(this.getInitialDate('end'));
		}
	}

	elementStart() {
		return this.datepickerStart.element;
	}

	elementEnd() {
		return this.datepickerEnd.element;
	}
}
